@font-face {
    font-family: Poppins-Regular;
    src: url('../assets/Fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Italic;
    src: url('../assets/Fonts/Poppins-Light.ttf');
}

:root {
    --color-base: rgb(229, 77, 46);
    --color-opacity: rgba(229, 77, 46, 0.3);
    --main-bg-color: rgb(240, 241, 254);
    --text-color: rgb(15, 15, 15);
}

@media (prefers-color-scheme: dark) {

    :root {
        --color-base: rgb(229, 77, 46);
        --color-opacity: rgba(229, 77, 46, 0.3);
        --main-bg-color: rgb(15, 15, 15);
        --text-color: rgb(240, 241, 254);
    }
}

.info {
    font-family: Poppins-Regular;
    z-index: 99;
    position: absolute;
    top: 0px;
    font-size: 12px;
    text-align: center;
    width: 30%;
    right: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-opacity);
    color: var(--text-color);
}

.info span {
    color: var(--color-base);
}

.copyright {
    font-family: Poppins-Italic;
    z-index: 99;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    opacity: 0.8;
    color: var(--text-color);
}

/* Mobile */
@media screen and (max-width: 600px) {
    .info {
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
}

/* Tablettes */
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .info {
        width: 50%;
    }
}

/* Desktop */
@media screen and (min-width: 1025px) {}