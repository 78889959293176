@font-face {
    font-family: Poppins-Regular;
    src: url('../assets/Fonts/Poppins-Regular.ttf');
}

.btn-submit {
    background-color: #E54D2E;
    color: #F0F1FE;
    padding: 6px 6px;
    border: none;
    border-radius: 5px;
    font-family: Poppins-Regular;
    font-size: 12px;
}

.formulaire {
    width: 350px;
    margin: auto;
}

input {
    margin-top: 10px;
    width: 90%;
    height: 35px;
    padding: 0px;
    border-radius: 5px;
    border: 1px solid #827A73;
    font-family: Poppins-Regular;
    padding-left: 10px;
    padding-right: 10px;
}

.text-area {
    width: 90%;
    height: 100px;
    resize: none;
    border-radius: 5px;
    border: 1px solid #827A73;
    font-family: Poppins-Regular;
    margin-top: 10px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
}

input:focus,
textarea:focus {
    outline: 2px solid #E54D2E;
}

.formulaire {
    text-align: center;
    margin-top: 20px;

}

.text-input {
    padding-left: 10px;
}

.text-area {
    padding-left: 10px;
    padding-top: 10px;
}

/* Mobile */

@media screen and (max-width: 600px) {
    .b-sociaux {
        text-align: center;
    }

    .formulaire {
        width: auto;
        margin-top: 2px;
    }

    .text-area {
        width: 90%;
        height: 60px;
        font-size: 10px;
    }

    input {
        margin-top: 10px;
        width: 90%;
        height: 25px;
        font-size: 10px;
    }

    .btn-submit {
        font-size: 8px;
    }
}

/* Tablettes */
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .formulaire {
        width: auto;
    }

}

/* Desktop */
@media screen and (min-width: 1025px) {
    .formulaire {
        width: auto;
    }
}