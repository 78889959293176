@font-face {
    font-family: Poppins-Regular;
    src: url('../assets/Fonts/Poppins-Regular.ttf');
}

.b-sociaux {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.btn-contact {
    background-color: #E54D2E;
    color: #F0F1FE;
    padding: 6px 6px;
    border: none;
    border-radius: 5px;
    font-family: Poppins-Regular;
    font-size: 12px;
    justify-content: center;
    display: flex;
    align-items: center;
}

button:hover {
    background-color: #8B8D98
}

.a-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.icone-svg-btn {
    width: 15px;
    height: 15px;
    fill: #F0F1FE;
    margin-right: 5px;
}

@media screen and (max-width: 600px) {
    .btn-contact {
        font-size: 8px;
    }

    .icone-svg-btn {
        width: 10px;
        height: 10px;
    }

}