.slider-div {
    position: fixed;
    display: block;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 300ms ease-in-out;
}

.slider-div.page1 {
    transform: translate3d(0px, 0vh, 0px);
}

.slider-div.page2 {
    transform: translate3d(0px, 100vh, 0px);
}

.slider-div.page3 {
    transform: translate3d(0px, 200vh, 0px);
}

.slider-div.page4 {
    transform: translate3d(0px, 300vh, 0px);
}

.page-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-bottom: 5px;
}

.active {
    background-color: rgb(229, 77, 46);
}

.inactive {
    background-color: rgba(76, 76, 77, 0.5);
}