@font-face {
    font-family: Poppins-Medium;
    src: url('../assets/Fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Regular;
    src: url('../assets/Fonts/Poppins-Regular.ttf');
}



@media (prefers-color-scheme: dark) {
    body {
        background-color: var(--main-bg-color);
        background-image: url('../assets/Img/BackgroundDark.png');
    }

    :root {
        --color-base: rgb(229, 77, 46);
        --color-opacity: rgba(229, 77, 46, 0.3);
        --main-bg-color: rgb(15, 15, 15);
        --text-color: rgb(240, 241, 254);
        --bull-color: rgba(240, 241, 254, 0.05);
    }
}

@media (prefers-color-scheme: light) {

    body {
        background-color: var(--main-bg-color);
        background-image: url('../assets/Img/BackgroundLight.png');
    }

    :root {
        --color-base: rgb(229, 77, 46);
        --color-opacity: rgba(229, 77, 46, 0.3);
        --main-bg-color: rgb(240, 241, 254);
        --text-color: rgb(15, 15, 15);
        --bull-color: rgba(76, 76, 77, 0.08);
    }

}

body {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

html,
body {
    padding: 0;
    margin: 0;
}

/*--------------------PAGE-1-----------------------*/

.b-1 {
    width: 50%;
    padding: 30px;
    background-color: var(--bull-color);
    border-radius: 5px;
}

.t-welcome {
    color: var(--color-base);
    font-family: Poppins-Medium;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
}

.t-name {
    color: var(--text-color);
    font-family: Poppins-Medium;
    padding: 0px;
    margin: 0px;
    font-size: 22px;
}

.t-presentation {
    color: var(--text-color);
    font-family: Poppins-Regular;
    font-size: 14px;
}

blockquote {
    border-left: 4px solid var(--color-opacity);
    padding-left: 12px;
}

/*--------------------PAGE-2-----------------------*/
.b-2 {
    width: 80%;
    text-align: center;
}

.b-2 .t-name {
    margin-bottom: 15px;

}

.b-com-all {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}

.b-com {
    flex: 1;
    background-color: var(--bull-color);
    border-radius: 5px;
}


.b-2 h3 {
    color: var(--color-base);
    font-family: Poppins-Medium;
    font-size: 16px;
}

.b-2 p {
    color: var(--text-color);
    font-family: Poppins-Regular;
    font-size: 14px;
    margin: 4px;
    text-align: left;
}

/*--------------------PAGE-3-----------------------*/
.b-com blockquote span {
    display: flex;
    align-items: center;
}

.icone-svg {
    fill: var(--text-color);
    height: 16px;
    width: 16px;
}

.b-3 {
    width: 80%;
    text-align: center;
}

.b-3 .t-name {
    margin-bottom: 15px;
}

.b-pro-all {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 2px;
}

.b-pro {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    background-color: var(--bull-color);
    border-radius: 5px;
}

.b-left {
    flex: 1.5;
}

.b-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    height: 200px;
    overflow: hidden;
}

.b-left h2 {
    color: var(--color-base);
    font-family: Poppins-Medium;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
}

.b-left p {
    color: var(--text-color);
    font-family: Poppins-Regular;
    font-size: 14px;
    margin-top: 4px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.b-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
    /* Couleur de fond pour b-bottom */
}

.b-bottom p {
    flex: 1;
    background-color: var(--color-opacity);
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    color: var(--text-color);
    font-family: Poppins-Regular;
    font-size: 12px;
}

.img-ref {
    background-image: url('../assets/Img/img_ref.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.img-ref-ar {
    background-image: url('../assets/Img/img_ref_ar.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.img-ref-copi {
    background-image: url('../assets/Img/img_ref_copilote.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

/*--------------------PAGE-4-----------------------*/
.b-4 {
    width: 30%;
    text-align: center;
}

.b-4 .t-name {
    margin-bottom: 15px;

}

.b-cont {
    flex: 1;
    background-color: var(--bull-color);
    border-radius: 5px;
    padding: 20px;
}

.b-4 p {
    color: var(--text-color);
    font-family: Poppins-Regular;
    font-size: 14px;
}

.b-4 .b-sociaux {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/*----------------------------------------------------------------*/
/* Mobile */
@media screen and (max-width: 600px) {
    .b-1 {
        width: 60%;
    }

    .b-4 {
        width: 60%;
    }

    .b-pro {
        flex: 1 1 100%;
        margin-bottom: 5px;
    }

    .b-com {
        flex: 1 1 100%;
    }

    .b-com h3 {
        font-size: 10px;
    }

    .b-2 p {
        font-size: 8px;
        margin: 4px;
    }

    .icone-svg {
        height: 10px;
        width: 10px;
    }

    .b-4 p {
        font-size: 8px;
        margin-top: 5px !important;
    }

    .t-name {
        font-size: 16px;

    }

    .t-welcome {
        font-size: 14px;
    }

    .t-presentation {
        color: var(--text-color);
        font-family: Poppins-Regular;
        font-size: 10px;
        /*text-align: justify;*/
    }

    .b-left h2 {
        font-size: 10px;
    }

    .b-left p {
        font-size: 8px;
    }

    .b-bottom p {
        font-size: 8px;
    }

    .b-right {
        height: 100px;
    }
}

/* Tablettes */
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .b-1 {
        width: 60%;
    }

    .b-4 {
        width: 60%;
    }
}

/* Desktop */
@media screen and (min-width: 1025px) {}